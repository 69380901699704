import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Grid, Stack } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Formulario from "../../forms/Formulario";
import Cookies from "js-cookie";
const auth_service = require("../../helpers/auth_service");
export default function Login() {
  const steps = ["Identificarse", "Ingresar contraseña y MFA"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [username, setUsername] = useState("");
  const [cambios, setCambios] = useState(0);
  useEffect(() => {}, [cambios]);
  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const afterUsername = (body) => {
    return new Promise((resolve, reject) => {
      auth_service
        .postData("/usr/pre-login", { usuario: body.username })
        .then((response) => {
          setUsername(body.username);
          handleNext();
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject({
            msg: `Esto pudo ocurrir por los siguientes motivos:
              <ul>
                <li>Ingreso erróneo de nombre de usuario</li>
                <li>Ingreso erróneo de contraseña</li>
                <li>Ingreso erróneo de token MFA</li>
                <li>Ingreso de token MFA expirado</li>
              </ul>`,
          });
        });
    });
  };
  const auth = (body) => {
    return new Promise((resolve, reject) => {
      auth_service
        .postData("/usr/login", {
          username: username,
          password: body.password,
          mfa: body.mfa,
        })
        .then((response) => {
          Cookies.set(
            process.env.REACT_APP_COOKIE_KEY,
            JSON.stringify({
              TokenType: response.data.AuthenticationResult.TokenType,
              accessToken: response.data.AuthenticationResult.AccessToken,
              refreshToken: response.data.AuthenticationResult.RefreshToken,
            }),
            { domain: process.env.REACT_APP_DOMAIN }
          );
          window.location.href = process.env.REACT_APP_REDIRECT_ENDPOINT;
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject({
            msg: `Esto pudo ocurrir por los siguientes motivos:
                    <ul>
                      <li>Ingreso erróneo de nombre de usuario</li>
                      <li>Ingreso erróneo de contraseña</li>
                      <li>Ingreso erróneo de token MFA</li>
                      <li>Ingreso de token MFA expirado</li>
                    </ul>`,
          });
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="xxl"
        style={{
          backgroundImage:
            "url(https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/Logotipos+Finales+ABG+2022-01.png)",
          backgroundSize: "25vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container justifyContent="center" style={{ width: "100%" }}>
            <Stack
              spacing={5}
              style={{
                width: "75%",
                border: "5px solid #1e3d52",
                borderRadius: "30px",
                padding: "1%",
              }}
            >
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {activeStep === 1 ? (
                <React.Fragment>
                  <Formulario
                    afterSubmit={auth}
                    formConfig={{
                      title: "",
                      submitTitle: "Iniciar Sesión",
                      formConfig: [
                        {
                          type: 2,
                          required: true,
                          value: "",
                          name: "password",
                          label: "Ingrese su contraseña",
                          title: "Contraseña",
                        },
                        {
                          type: 2,
                          required: true,
                          value: "",
                          name: "mfa",
                          label:
                            "Ingrese su código MFA que llegó a su bandeja de email",
                          title: "Código MFA",
                        },
                      ],
                    }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Formulario
                    afterSubmit={afterUsername}
                    formConfig={{
                      title: "",
                      submitTitle: "Continuar",
                      formConfig: [
                        {
                          type: 1,
                          required: true,
                          value: "",
                          name: "username",
                          label: "Ingrese su nombre de usuario",
                          title: "Nombre de Usuario",
                        },
                      ],
                    }}
                  />
                </React.Fragment>
              )}
            </Stack>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
