import * as React from "react";
import Login from "./components/login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
export default function App() {
  return (
    <Router>
      <Routes>
        <Route estrict path="/" element={<Navigate to="/auth" replace />} />
        <Route path="/auth" element={<Login />}></Route>
      </Routes>
    </Router>
  );
}
